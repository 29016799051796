import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';
import { FeatureFlag } from '../../@types/FeatureFlag';

export const fetchPasswordlessMagicLinkEnabled = createAsyncThunk<
  FeatureFlag,
  void,
  { rejectValue: string }
>(
  'featureFlags/fetchPasswordlessMagicLinkEnabled',
  async (_, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        '/feature-flags/passwordless_magic_link_enabled',
      );
      return response.data;
    } catch (error: any) {
      Sentry.captureException(error);
      const val = error.message || error;
      return rejectWithValue(val);
    }
  },
);
