import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import { AxiosError } from 'axios';
import Axios from '../../utils/Axios';

export const fetchSelf = createAsyncThunk<
  User,
  void,
  { rejectValue: AxiosError }
>('user/fetchSelf', async (_, { rejectWithValue }) => {
  try {
    const response = await Axios.get(`/contacts/me/`);

    if (response.status !== 200) {
      Sentry.captureException(response.data);
      return rejectWithValue(response.data as AxiosError);
    }
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return rejectWithValue(error as AxiosError);
  }
});
