/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import { StoreStatus } from '../../@types/StoreStatus';

import {
  completePassword,
  forgotPassword,
  forgotPasswordCheck,
  refreshToken,
  resendForgotPassword,
  resetPassword,
  signIn,
  signOut,
} from './actions';

export enum ForgotPasswordStates {
  EMAIL_DOES_NOT_EXIST_IN_SYSTEM = 'EMAIL_DOES_NOT_EXIST_IN_SYSTEM',
  EMAIL_IN_SYSTEM_PASSWORD_RESET_SENT = 'EMAIL_IN_SYSTEM_PASSWORD_RESET_SENT',
  SELECT_SMS_OR_EMAIL_NEW_PASSWORD_OPTION = 'SELECT_SMS_OR_EMAIL_NEW_PASSWORD_OPTION',
  TEMPORARY_PASSWORD_SENT_SUCCESSFULLY = 'TEMPORARY_PASSWORD_SENT_SUCCESSFULLY',
  WEIRD_ERROR = 'WEIRD_ERROR',
  NOT_SUPPORTED_FOR_CUSTOMERS = 'NOT_SUPPORTED_FOR_CUSTOMERS',
  EMAIL_IS_REQUIRED = 'EMAIL_IS_REQUIRED',
}

export interface AuthState {
  isAuthenticated: boolean;
  message: string;
  status: StoreStatus;
  user: CognitoUserAmplify | null;
  forgotPasswordState: any | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  message: '',
  user: null,
  status: StoreStatus.Idle,
  forgotPasswordState: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => ({
      ...state,
      isAuthenticated: true,
      user: payload,
    }),
    resetIdleState: (state) => ({
      ...state,
      status: StoreStatus.Idle,
    }),
    setIsAuthenticated: (state, { payload }) => ({
      ...state,
      isAuthenticated: payload,
    }),
    resetDefaultState: () => initialState,
    resetMessageAndStatus: (state) => ({
      ...state,
      message: '',
      status: StoreStatus.Idle,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => ({
      ...state,
      status: StoreStatus.Succeeded,
      isAuthenticated: true,
      user: payload,
    }));
    builder.addCase(forgotPassword.fulfilled, (state) => ({
      ...state,
      status: StoreStatus.Succeeded,
    }));
    builder.addCase(resendForgotPassword.fulfilled, (state) => ({
      ...state,
      status: StoreStatus.Idle,
    }));
    builder.addCase(resetPassword.fulfilled, (state) => ({
      ...state,
      status: StoreStatus.CompletedResetPassword,
    }));
    builder.addCase(completePassword.fulfilled, (state, { payload }) => ({
      ...state,
      user: payload,
      status: StoreStatus.CompletedPassword,
      isAuthenticated: true,
    }));
    builder.addCase(signOut.fulfilled, () => initialState);
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => ({
      ...state,
      user: payload as any,
      isAuthenticated: true,
      status: StoreStatus.Succeeded,
    }));
    builder.addCase(refreshToken.rejected, (state) => ({
      ...state,
      status: StoreStatus.Failed,
    }));
    builder.addCase(
      forgotPasswordCheck.fulfilled,
      (state, { payload, meta }) => {
        // if payload is:
        // { send_forgot_password_email: true }
        // then the email got sent out and they already exist in our system
        // if payload is:
        // { send_forgot_password_email: false }
        // then the email is in our system however they need to finish their setup.
        state.status = StoreStatus.Idle;
        if (!payload) {
          state.forgotPasswordState =
            ForgotPasswordStates.EMAIL_DOES_NOT_EXIST_IN_SYSTEM;
          return;
        }
        if (payload.sent_temporary_password === true) {
          state.forgotPasswordState =
            ForgotPasswordStates.TEMPORARY_PASSWORD_SENT_SUCCESSFULLY;
        } else if (payload.sent_forgot_password === true) {
          state.forgotPasswordState =
            ForgotPasswordStates.EMAIL_IN_SYSTEM_PASSWORD_RESET_SENT;
        } else if (typeof meta.arg.send_sms === 'undefined') {
          state.forgotPasswordState =
            ForgotPasswordStates.SELECT_SMS_OR_EMAIL_NEW_PASSWORD_OPTION;
        } else {
          // Capture if this occurs means something bad mkay.
          Sentry.captureMessage(
            `Email: ${meta.arg.email} not found. Major Red Flag`,
          );
          state.forgotPasswordState = ForgotPasswordStates.WEIRD_ERROR;
        }
      },
    );
    builder.addCase(
      forgotPasswordCheck.rejected,
      (state: AuthState, { payload }: { payload: any; error: any }) => {
        let errorState;
        if (
          payload &&
          payload.error === 'Email does not exist in our system.'
        ) {
          errorState = ForgotPasswordStates.EMAIL_DOES_NOT_EXIST_IN_SYSTEM;
        } else if (
          payload &&
          payload.error === 'This endpoint does not support customers.'
        ) {
          errorState = ForgotPasswordStates.NOT_SUPPORTED_FOR_CUSTOMERS;
        } else if (
          payload &&
          payload.error === 'Email is required to be filled out.'
        ) {
          errorState = ForgotPasswordStates.EMAIL_IS_REQUIRED;
        }
        state.status = StoreStatus.Idle;
        state.forgotPasswordState = errorState;
      },
    );

    // Handle pending & rejected requests
    builder.addMatcher(
      isAnyOf(
        signIn.pending,
        forgotPassword.pending,
        resendForgotPassword.pending,
        resetPassword.pending,
        signOut.pending,
        refreshToken.pending,
        completePassword.pending,
        forgotPasswordCheck.pending,
      ),
      (state) => ({
        ...state,
        status: StoreStatus.Loading,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        signIn.rejected,
        forgotPassword.rejected,
        resendForgotPassword.rejected,
        resetPassword.rejected,
        signOut.rejected,
        completePassword.rejected,
      ),
      (state, { error, meta, payload }) => {
        let payloadDetails = {};

        if (payload) {
          payloadDetails = payload;
        }
        Sentry.captureEvent({
          message:
            'Error with signIn/forgotPassword/resend/reset/signout/complete capture',
          extra: {
            request_details: {
              ...payloadDetails,
              ...meta,
            },
          },
        });
        state.status = StoreStatus.Failed;
        state.message = error.message || '';
      },
    );
  },
});

export const {
  setUser,
  resetIdleState,
  resetDefaultState,
  resetMessageAndStatus,
  setIsAuthenticated,
} = AuthSlice.actions;

export default AuthSlice.reducer;
