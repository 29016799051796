import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import featureFlagsReducer from './featureFlags/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  featureFlags: featureFlagsReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default store;
