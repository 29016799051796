import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from '../../store';
// import aws cognito config
import '../../config/Cognito/index';

function ReduxProvider({ element }) {
  return (
    <Provider store={store}>
      <noscript>
        <div className="text-center mx-auto max-w-md w-full mt-12 text-sm bg-warning-500 bg-opacity-60 text-black border border-warning-500 border-solid rounded p-4">
          ⚠️ Your Javascript is <strong>disabled</strong>. You must&nbsp; enable
          Javascript in order for login to work correctly. <br /> <br />
          <p className="text-left text-sm">
            <b>On Safari</b>: Click on the top corner of your mac: Safari &gt;
            Settings &gt; Security &gt; and toggle{' '}
            <strong>Web Content: Enable Javascript</strong>
            <br />
            <br />
            <b>On Chrome</b>: enter this url in your browser:&nbsp;
            <code>chrome://settings/content/javascript</code> and then toggle
            <strong>&nbsp;Sites can use Javascript.</strong>
          </p>
        </div>
      </noscript>
      {element}
      <ToastContainer />
    </Provider>
  );
}

export default ReduxProvider;
