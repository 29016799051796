/* eslint-disable camelcase */
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import axios from 'axios';

const baseURL = process.env.GATSBY_API_URL || 'http://localhost:8000';

export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
  const cognitoUser: CognitoUserAmplify = await Auth.currentAuthenticatedUser();
  return cognitoUser?.attributes;
});

// export const signUp = createAsyncThunk('auth/signUp', async ({ email, password, firstName, lastName }: SignUpForm) => {
//   await Auth.signUp({
//     username: email,
//     password,
//     attributes: { given_name: firstName, family_name: lastName },
//   })
// })

// export const confirmSignUp = createAsyncThunk('auth/confirmSignUp', async ({ email, code }: ConfirmSignUpForm) => {
//   return Auth.confirmSignUp(email, code)
// })

// export const resendSignUp = createAsyncThunk('auth/resendSignUp', async ({ email }: ConfirmSignUpForm) => {
//   return Auth.resendSignUp(email)
// })

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password }: SignInForm) => {
    const cognitoUser: CognitoUserAmplify = await Auth.signIn(email, password);
    return cognitoUser;
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ email }: ForgotPasswordForm) => await Auth.forgotPassword(email),
);

export const resendForgotPassword = createAsyncThunk(
  'auth/resendForgotPassword',
  async ({ email }: ForgotPasswordForm) => await Auth.forgotPassword(email),
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ email, code, password }: ResetPasswordForm) =>
    Auth.forgotPasswordSubmit(email, code, password),
);

export const signOut = createAsyncThunk('auth/signOut', async () =>
  Auth.signOut(),
);

export const completePassword = createAsyncThunk(
  'auth/completePassword',
  async ({ password, user }: { password: string; user: CognitoUser }) => {
    const newUser = await Auth.completeNewPassword(user, password);
    return newUser;
  },
);

// if sent_forgot_password_email: true then they exist in our system and send them to the code page
// if sent_temporary_password: true then the temporary password has been sent

export const forgotPasswordCheck = createAsyncThunk(
  'auth/forgotPasswordCheck',
  async (
    {
      email,
      send_sms,
    }: {
      email: string;
      send_sms?: boolean;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        `${baseURL}/contacts/forgot-password/`,
        {
          email,
          ...(typeof send_sms !== 'undefined' && {
            send_sms,
          }),
        },
      );
      return response.data;
    } catch (err) {
      const typedErr: any = err;
      Sentry.captureException(err);
      if (!typedErr.response) {
        return rejectWithValue(err);
      }
      return rejectWithValue(typedErr.response.data);
    }
  },
);
