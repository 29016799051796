import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://ff996de83fd6461b89e48710a83d4015@o385035.ingest.sentry.io/4505172457029632',
  sampleRate: 0.7,
  environment:
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development',
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: [
        'https://cognito-idp.us-east-1.amazonaws.com/',
        'https://api.listwithclever.com/',
      ],
      networkRequestHeaders: ['X-Custom-Header', 'Cache-Control'],
      networkResponseHeaders: ['X-Custom-Header', 'Referrer-Policy'],
    }),
  ],
});
