/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { FeatureFlag } from '../../@types/FeatureFlag';
import { fetchPasswordlessMagicLinkEnabled } from './actions';

export interface FeatureFlagsState {
  loading: boolean;
  success: boolean;
  errorMessage: string | undefined;
  featureFlags: FeatureFlag[];
}

const initialState: FeatureFlagsState = {
  success: false,
  loading: false,
  errorMessage: '',
  featureFlags: [],
};

const featureFlags = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    resetFeatureFlags: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPasswordlessMagicLinkEnabled.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.success = true;

        const index = state.featureFlags.findIndex(
          (el) => el.name === 'passwordless_magic_link_enabled',
        );
        if (index > -1) {
          state.featureFlags[index] = payload;
        } else {
          state.featureFlags.push(payload);
        }

        state.errorMessage = undefined;
      },
    );
    builder.addMatcher(
      isAnyOf(fetchPasswordlessMagicLinkEnabled.pending),
      (state) => ({
        ...state,
        loading: true,
      }),
    );
    builder.addMatcher(
      isAnyOf(fetchPasswordlessMagicLinkEnabled.rejected),
      (state, { payload }) => ({
        ...state,
        loading: false,
        success: false,
        errorMessage: payload,
      }),
    );
  },
});

export const { resetFeatureFlags } = featureFlags.actions;
export default featureFlags.reducer;
