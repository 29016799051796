/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { fetchSelf } from './actions';

export interface UserState {
  user?: User;
  loading: boolean;
  error: AxiosError | null | undefined;
}

const initialState = {
  user: undefined,
  loading: false,
  error: null,
} as UserState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserDefaultState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSelf.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSelf.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(fetchSelf.rejected, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
      state.user = undefined;
    });
  },
});
export const { resetUserDefaultState } = userSlice.actions;
export default userSlice.reducer;
